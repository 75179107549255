
import {
  defineComponent,
  onBeforeMount,
  ref,
  reactive,
  toRefs,
  nextTick,
  inject,
  onActivated,
  onMounted,
  onBeforeUnmount,
  getCurrentInstance
} from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { ElMessage, ElMessageBox } from 'element-plus'
import { hotIssueApi } from '@/api/modules/hotIssue'

export default defineComponent({
  name: 'helpCenterHotIssue',
  setup (props, ctx) {
    const route = useRoute()
    const router = useRouter()

    const { proxy } = getCurrentInstance() as any

    // 导入mitt
    const mitter: any = inject('mitter')

    // 获取refs
    const tableListRef = ref()

    onBeforeMount(() => {
      const { id } = route.query
      data.projectId = Number(id)
      data.init()
    })

    onMounted(() => {
      // 监听订阅推送
      mitter.on('helpCenterHotIssueUpdateList', () => {
        // console.log('订阅触发')
        data.getList('place')
      })
    })

    onActivated(() => {
      // 监听路由id是否更改
      const { id } = route.query
      if (Number(id) !== data.projectId) {
        data.projectId = Number(id)
        // 获取详情
        data.init()
      }
    })

    const data: any = reactive({
      projectId: null,
      projectStatus: inject('projectStatus'),
      list: [],
      loading: true,
      query: {
        page: 1,
        paginate: 20,
        name: '',
        status: 0
      },
      total: 0, // 总条数
      // 是否显示排序
      ifSorting: false,

      // 初始化
      init () {
        console.log('热门问题-初始化')
        data.query.name = ''
        data.query.status = 0
        proxy.$globalFun.ifAuth(route, '热门问题-列表') && data.getList('place')
      },

      // 获取列表
      async getList (place: string) {
        // 重置页数
        if (place) {
          data.query.page = 1
          // 滚动条回到顶部
          nextTick(() => {
            tableListRef.value && (tableListRef.value.$el.children[2].scrollTop = 0)
          })
        }

        data.query.project_id = data.projectId
        const { res } = await hotIssueApi.getList(data.query)
        console.log('获取热门问题列表', res.data)
        data.loading = false
        data.list = res.data
        data.total = res.total

        // 是否排序状态
        data.ifSorting = false
      },
      // 每页显示条数和当前页码
      handleSizeChange (newSize: number) {
        data.query.paginate = newSize
        data.getList()
        // 滚动条回到顶部
        nextTick(() => {
          tableListRef.value.$el.children[2].scrollTop = 0
        })
      },
      handleCurrentChange (newPage: number) {
        data.query.page = newPage
        data.getList()
        // 滚动条回到顶部
        nextTick(() => {
          tableListRef.value.$el.children[2].scrollTop = 0
        })
      },

      // 新增、编辑、查看详情
      onAddOrEdit (type: number, id: number) {
        /* 记录滚动条位置 */
        const scrollTop: number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        localStorage.setItem('projectDetailsScrollTop', scrollTop + '')

        const projectId = data.projectId
        let path = ''
        switch (type) {
          case 0:
            path = `add?projectId=${projectId}`
            break

          case 1:
            path = `edit?projectId=${projectId}&id=${id}`
            break

          default:
            path = `show?projectId=${projectId}&id=${id}`
            break
        }
        router.push('hotIssue/' + path)
      },

      // 删除
      async onDel (id: number) {
        const confirmResult = await ElMessageBox.confirm('是否删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch((err: any) => err)
        if (confirmResult !== 'confirm') return

        await hotIssueApi.del({ id })
        ElMessage.success('删除成功')
        data.getList()
      },

      // 状态改变
      async editStatus (item: any) {
        // console.log('状态改变', item)
        try {
          const confirmResult = await ElMessageBox.confirm('是否更变状态？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).catch((err) => err)
          if (confirmResult !== 'confirm') throw new Error('取消操作')

          const form: any = {
            id: item.id,
            status: item.status
          }
          await hotIssueApi.changeStatus(form)
          ElMessage.success('状态改变成功')
          data.getList()
        } catch (error) {
          // 恢复原来的样子
          item.status = item.status === 1 ? 2 : 1
        }
      },

      // 排序
      onSortingShow () {
        for (const i of data.list) {
          i.newSorting = i.sort
        }
        data.ifSorting = true
      },
      async onSortingSave () {
        const confirmResult = await ElMessageBox.confirm('是否重新排序？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch((err: any) => err)
        if (confirmResult !== 'confirm') return

        // console.log('排序确认', data.list)

        const form: any = {
          ids: [],
          sorts: [],
          project_id: data.projectId
        }
        for (const i of data.list) {
          form.ids.push(i.id)
          form.sorts.push(i.newSorting)
        }
        // console.log('form', form)
        await hotIssueApi.changeSort(form)
        ElMessage.success('排序成功')
        data.ifSorting = false

        data.getList()
      }
    })

    onBeforeUnmount(() => {
      // console.log('离开')
      // 移除订阅推送
      mitter.off('helpCenterHotIssueUpdateList')
    })

    return {
      ...toRefs(data),
      tableListRef
    }
  }
})
