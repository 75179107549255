
import { defineComponent, onBeforeMount, ref, reactive, toRefs, nextTick, onActivated, getCurrentInstance } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { ElMessage, ElMessageBox } from 'element-plus'
import { feedbackApi } from '@/api/modules/feedback'

export default defineComponent({
  name: 'helpCenterFeedback',
  setup (props, ctx) {
    const route = useRoute()
    const router = useRouter()

    const { proxy } = getCurrentInstance() as any

    // 获取refs
    const tableListRef = ref()

    onBeforeMount(() => {
      const { id } = route.query
      data.projectId = Number(id)
      data.init()
    })

    onActivated(() => {
      // 监听路由id是否更改
      const { id } = route.query
      if (Number(id) !== data.projectId) {
        data.projectId = Number(id)
        // 获取详情
        data.init()
      }
    })

    const data: any = reactive({
      projectId: null,
      list: [],
      loading: true,
      query: {
        page: 1,
        paginate: 20,
        title: ''
      },
      total: 0, // 总条数

      // 初始化
      init () {
        console.log('意见反馈-初始化')
        data.query.title = ''
        proxy.$globalFun.ifAuth(route, '反馈意见-列表') && data.getList('place')
      },

      // 获取列表
      async getList (place: string) {
        // 重置页数
        if (place) {
          data.query.page = 1
          // 滚动条回到顶部
          nextTick(() => {
            tableListRef.value && (tableListRef.value.$el.children[2].scrollTop = 0)
          })
        }

        data.query.project_id = data.projectId

        const { res } = await feedbackApi.getList(data.query)
        console.log('获取列表', res.data)
        data.loading = false
        data.list = res.data
        data.total = res.total
      },
      // 每页显示条数和当前页码
      handleSizeChange (newSize: number) {
        data.query.paginate = newSize
        data.getList()
        // 滚动条回到顶部
        nextTick(() => {
          tableListRef.value.$el.children[2].scrollTop = 0
        })
      },
      handleCurrentChange (newPage: number) {
        data.query.page = newPage
        data.getList()
        // 滚动条回到顶部
        nextTick(() => {
          tableListRef.value.$el.children[2].scrollTop = 0
        })
      },

      // 备注
      async onNote (id: number) {
        const prompt = await ElMessageBox.prompt('请输入你的备注', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern: /\S/,
          inputErrorMessage: '请输入内容'
        }).catch((err: any) => err)
        if (prompt === 'cancel') return
        console.log('prompt', prompt.value)

        const form: any = {
          id,
          remark: prompt.value
        }
        // console.log('form', form)
        await feedbackApi.remark(form)
        ElMessage.success('已备注')
        data.getList()
      },

      // 查看详情
      async onShowDetails (id: number) {
        /* 记录滚动条位置 */
        const scrollTop: number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        localStorage.setItem('projectDetailsScrollTop', scrollTop + '')

        router.push('feedback/show?id=' + id)
      },

      // 删除
      async onDel (id: number) {
        const confirmResult = await ElMessageBox.confirm('是否删除此迭代？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch((err: any) => err)
        if (confirmResult !== 'confirm') return

        await feedbackApi.del({ id })
        ElMessage.success('删除成功')
        data.getList()
      }
    })

    return {
      ...toRefs(data),
      tableListRef
    }
  }
})
