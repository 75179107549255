
import {
  defineComponent,
  onBeforeMount,
  ref,
  reactive,
  toRefs,
  provide,
  onActivated,
  inject,
  getCurrentInstance,
  onBeforeUnmount,
  onMounted
} from 'vue'
import { useRoute, useRouter } from 'vue-router'

import AddOrEditDialog from './addOrEditDialog.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { operationManualApi } from '@/api/modules/operationManual'

export default defineComponent({
  name: 'helpCenterOperationManual',
  components: {
    AddOrEditDialog
  },
  setup (props, ctx) {
    const route = useRoute()
    const router = useRouter()

    const { proxy } = getCurrentInstance() as any

    // 导入mitt
    const mitter: any = inject('mitter')

    // 获取refs
    const addOrEditDialogRef = ref()
    const tableRef = ref()

    onBeforeMount(() => {
      const { id } = route.query
      data.projectId = Number(id)
      data.init()
    })

    onMounted(() => {
      // 监听订阅推送
      mitter.on('helpCenterOperationManualUpdateList', () => {
        // console.log('订阅触发')
        data.getList('place')
      })
    })

    onActivated(() => {
      // 监听路由id是否更改
      const { id } = route.query
      if (Number(id) !== data.projectId) {
        data.projectId = Number(id)
        // 获取详情
        data.init()
      }
    })

    const data: any = reactive({
      projectId: null,
      projectStatus: inject('projectStatus'),
      list: [],
      loading: true,
      query: {
        name: ''
      },
      total: 0, // 总条数
      // 是否显示排序
      ifSorting: false,

      // 初始化
      init () {
        console.log('操作手册-初始化')
        data.query.name = ''
        proxy.$globalFun.ifAuth(route, '操作手册-列表') && data.getList('place')
      },

      // 获取列表
      async getList () {
        data.query.project_id = data.projectId
        const { res } = await operationManualApi.getList(data.query)
        console.log('获取操作手册列表', res)
        data.loading = false
        data.list = res

        // 是否排序状态
        data.ifSorting = false
      },

      // 新增、编辑
      onAddOrEdit (id: number) {
        addOrEditDialogRef.value.showAddOrEdit(data.projectId, id)
      },

      // 删除
      async onDel (id: number) {
        const confirmResult = await ElMessageBox.confirm('是否删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch((err: any) => err)
        if (confirmResult !== 'confirm') return

        await operationManualApi.del({ id })
        ElMessage.success('删除成功')
        data.getList()
      },

      // 状态改变
      async editStatus (item: any) {
        // console.log('状态改变', item)
        try {
          const confirmResult = await ElMessageBox.confirm('是否更变状态？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).catch((err) => err)
          if (confirmResult !== 'confirm') throw new Error('取消操作')

          const form: any = {
            id: item.id,
            status: item.status
          }
          await operationManualApi.changeStatus(form)
          ElMessage.success('状态改变成功')
          data.getList()
        } catch (error) {
          // 恢复原来的样子
          item.status = item.status === 1 ? 2 : 1
        }
      },

      // 排序
      onSortingShow () {
        tableRef.value.clearRowExpand()
        for (const i of data.list) {
          i.newSorting = i.sort
        }
        data.ifSorting = true
      },
      async onSortingSave () {
        await data.sortingSava(data.list)
        data.ifSorting = false
        data.getList()
      },

      /* 子集 */
      // 新增、编辑、查看
      onEditItem (type: number, id: number, parentId: number) {
        /* 记录滚动条位置 */
        const scrollTop: number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        localStorage.setItem('projectDetailsScrollTop', scrollTop + '')

        const projectId = data.projectId
        let path = ''
        switch (type) {
          case 0:
            path = `add?projectId=${projectId}&parentId=${parentId}`
            break

          case 1:
            path = `edit?projectId=${projectId}&id=${id}`
            break

          default:
            path = `show?projectId=${projectId}&id=${id}`
            break
        }
        router.push('operationManual/' + path)
      },

      // 排序
      onSortingItemShow (index: number) {
        for (const i of data.list[index].children) {
          i.newSorting = i.sort
        }
        data.list[index].children.ifSortingItem = true
      },
      async onSortingItemSave (index: any) {
        await data.sortingSava(data.list[index].children)
        data.list[index].children.ifSortingItem = false
        data.getList()
      },

      // 排序方法
      sortingSava (list: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise<void>(async (resolve) => {
          const confirmResult = await ElMessageBox.confirm('是否重新排序？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).catch((err: any) => err)
          if (confirmResult !== 'confirm') return

          console.log('排序确认', list)
          const form: any = {
            project_id: data.projectId,
            ids: [],
            sorts: []
          }
          for (const i of list) {
            form.ids.push(i.id)
            form.sorts.push(i.newSorting)
          }
          console.log('form', form)
          await operationManualApi.changeSort(form)
          ElMessage.success('排序成功')
          resolve()
        })
      }
    })

    // 注入
    provide('updateList', data.getList)

    onBeforeUnmount(() => {
      // console.log('离开')
      // 移除订阅推送
      mitter.off('helpCenterOperationManualUpdateList')
    })

    return {
      ...toRefs(data),
      addOrEditDialogRef,
      tableRef
    }
  }
})
