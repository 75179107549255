import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"margin-top":"10px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_tabs, {
      modelValue: _ctx.activeName,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeName) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_tab_pane, {
          label: "操作手册",
          name: "operationManual",
          lazy: ""
        }),
        _createVNode(_component_el_tab_pane, {
          label: "热门问题",
          name: "hotIssue",
          lazy: ""
        }),
        _createVNode(_component_el_tab_pane, {
          label: "意见反馈",
          name: "feedback",
          lazy: ""
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(), _createBlock(_KeepAlive, null, [
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.activeName)))
      ], 1024))
    ])
  ]))
}