
import { defineComponent, onBeforeMount, reactive, toRefs, nextTick, onActivated, provide, ref } from 'vue'
import { useRoute } from 'vue-router'
import { projectApi } from '@/api/modules/project'
import { ElMessage, ElMessageBox, ElLoading } from 'element-plus'

import Iteration from './components/iteration/index.vue'
import HelpCenter from './components/helpCenter/index.vue'

export default defineComponent({
  components: {
    Iteration,
    HelpCenter
  },
  name: 'projectDetails',
  setup (props, ctx) {
    const route = useRoute()

    onBeforeMount(() => {
      const { id } = route.query
      data.id = Number(id)
      // 获取详情
      data.getInfo()
    })

    onActivated(() => {
      // 监听路由id是否更改
      const { id } = route.query
      if (Number(id) !== data.id) {
        data.id = Number(id)
        // 获取详情
        data.getInfo()
      }

      const projectDetailsScrollTop: string | null = localStorage.getItem('projectDetailsScrollTop')
      // console.log('projectDetailsScrollTop', projectDetailsScrollTop)
      // 滚动到上传预览的位置
      nextTick(() => {
        projectDetailsScrollTop && window.scroll(0, Number(projectDetailsScrollTop))
        localStorage.setItem('projectDetailsScrollTop', '')
      })
    })

    // 注入项目状态
    const projectStatus = ref('')
    const membersProjectList = ref('')

    const data: any = reactive({
      id: null,
      info: null,
      statusColor: ['', '#000', '#409eff', '#E6A23C', '#A6A9AD', '#F56C6C'],

      // 获取详情
      async getInfo () {
        const id = data.id
        const { res } = await projectApi.getInfo({ id })
        console.log('获取详情', res)

        // 数据处理
        const membersProject: string[] = []
        for (const i of res.user_type2) {
          membersProject.push(i.username)
        }
        res.membersProject = membersProject.join('、')

        data.info = res
        projectStatus.value = res.status
        membersProjectList.value = data.info.user_type2
      },
      // 更改状态
      async onStatus (status: number, title: string) {
        console.log('status', status)
        const id = data.info.id

        const confirmResult = await ElMessageBox.confirm(`是否${title}此项目`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch((err: any) => err)
        if (confirmResult !== 'confirm') return

        await projectApi.changeStatus({ id, status })
        ElMessage.success(title + '成功')
        data.getInfo()
      },
      // 导出
      async onExport () {
        const confirmResult = await ElMessageBox.confirm('是否导出？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch((err: any) => err)
        if (confirmResult !== 'confirm') return

        const loading = ElLoading.service({
          lock: true,
          text: '导出中',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        try {
          const { res } = await projectApi.export({ project_id: data.id })
          console.log('导出-res', res.path)
          window.location = res.path
          loading.close()
        } catch (error) {
          console.error('error', error)
          loading.close()
        }
      }
    })

    provide('membersProjectList', membersProjectList)
    provide('projectStatus', projectStatus)

    return {
      ...toRefs(data)
    }
  }
})
