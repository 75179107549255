
import { defineComponent, reactive, toRefs } from 'vue'

import operationManual from './operationManual/index.vue'
import hotIssue from './hotIssue/index.vue'
import feedback from './feedback/index.vue'

export default defineComponent({
  name: 'helpCenter',
  components: {
    operationManual,
    hotIssue,
    feedback
  },
  setup (props, ctx) {
    const data: any = reactive({
      projectId: null,
      activeName: 'operationManual'
    })

    return {
      ...toRefs(data)
    }
  }
})
