
import { defineComponent, inject, ref, reactive, toRefs, getCurrentInstance } from 'vue'

import { ElMessage } from 'element-plus'
import { iterationApi } from '@/api/modules/iteration'

export default defineComponent({
  name: 'iterationAddOrEditDialog',
  props: {
    // 项目id
    projectId: {
      type: Number
    }
  },
  setup (props, ctx) {
    // 获取全局变量
    const { proxy } = getCurrentInstance() as any

    // 获取refs
    const addOrEditFormRef = ref()

    // 接收父级的方法
    const updateList: any = inject('updateList')

    // 新增、编辑表单数据
    const addOrEditFormInit: any = () => {
      return {
        name: '',
        project_id: '',
        user_id_type1: '',
        user_ids_type2: [],
        date: [],
        target: ''
      }
    }

    const data = reactive({
      addOrEditDialog: false,
      formDisabled: false,
      addOrEditType: 0, // 0新增, 1编辑, 2查看
      addOrEditTypeTitle: ['新增', '编辑', '查看'],
      addOrEditForm: addOrEditFormInit(),
      addOrEditRules: {
        name: [{ required: true, message: '请输入迭代名称', trigger: 'blur' }],
        user_id_type1: [{ required: true, message: '请选择迭代负责人', trigger: 'change' }],
        user_ids_type2: [{ required: true, message: '请选择项迭代成员', trigger: 'change' }],
        date: [{ required: true, message: '请选择迭代时间段', trigger: 'change' }]
        // target: [{ required: true, message: '请输入迭代目标', trigger: 'blur' }]
      },
      determineBtnLoading: false,
      membersProjectList: inject('membersProjectList'),

      // 对话框显示
      async showAddOrEdit (type: number, id: number) {
        data.addOrEditType = type
        data.formDisabled = type === 2

        console.log('membersProjectList', data.membersProjectList)

        // 有id时赋值
        if (id) {
          const { res } = await iterationApi.getInfo({ id })
          console.log('根据id获取详情', res)
          data.addOrEditForm = res

          // 数据处理
          if (type === 2 && !res.target) {
            data.addOrEditForm.target = '-'
          }
          data.addOrEditForm.date = [res.start_date, res.end_date]
          data.addOrEditForm.user_id_type1 = res.user_type1.user_id
          const user_ids_type2 = []
          for (const i of res.user_type2) {
            user_ids_type2.push(i.user_id)
          }
          data.addOrEditForm.user_ids_type2 = user_ids_type2
        }
        data.addOrEditDialog = true
      },

      // 对话框关闭
      addOrEditCancel () {
        // console.log('对话框关闭')
        addOrEditFormRef.value.resetFields()
        data.addOrEditForm = Object.assign({}, addOrEditFormInit())
      },

      // 确认
      addOrEditDetermine () {
        addOrEditFormRef.value.validate(async (valid: any) => {
          if (!valid) return

          // 处理提交数据
          if (data.addOrEditForm.date.length) {
            data.addOrEditForm.start_date = data.addOrEditForm.date[0]
            data.addOrEditForm.end_date = data.addOrEditForm.date[1]
          }
          const form = proxy.$globalFun.deepClone(data.addOrEditForm)
          form.user_ids_type2 = JSON.stringify(form.user_ids_type2)
          form.project_id = props.projectId
          console.log('form', form)

          try {
            data.determineBtnLoading = true
            await iterationApi.save(form)
            ElMessage.success(`${data.addOrEditTypeTitle[data.addOrEditType]}成功`)
            data.addOrEditDialog = false
            data.determineBtnLoading = false

            // 父级更新列表
            updateList('place')
          } catch (error) {
            data.determineBtnLoading = false
          }
        })
      }
    })

    return {
      ...toRefs(data),
      addOrEditFormRef
    }
  }
})
