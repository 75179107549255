
import { defineComponent, onBeforeMount, ref, reactive, toRefs, nextTick, provide, onActivated, inject } from 'vue'
import { useRoute } from 'vue-router'
import AddOrEditDialog from './addOrEditDialog.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { iterationApi } from '@/api/modules/iteration'

export default defineComponent({
  name: 'iteration',
  components: {
    AddOrEditDialog
  },
  setup (props, ctx) {
    const route = useRoute()

    // 获取refs
    const tableListRef = ref()
    const addOrEditDialogRef = ref()
    const queryFormRef = ref()

    onBeforeMount(() => {
      const { id } = route.query
      data.projectId = Number(id)
      data.init()
    })

    onActivated(() => {
      // 监听路由id是否更改
      const { id } = route.query
      if (Number(id) !== data.projectId) {
        data.projectId = Number(id)
        // 获取详情
        data.init()
      }
    })

    const data: any = reactive({
      projectId: null,
      projectStatus: inject('projectStatus'),
      list: [],
      loading: true,
      query: {
        project_id: '',
        page: 1,
        paginate: 20,
        name: '',
        user_type1_name: '',
        date_interval: [],
        status: 0
      },
      total: 0, // 总条数

      // 初始化
      init () {
        // console.log('迭代-更新数据')
        queryFormRef.value && queryFormRef.value.resetFields()
        data.getList('place')
      },

      // 获取列表
      async getList (place: string) {
        // 重置页数
        if (place) {
          data.query.page = 1
          // 滚动条回到顶部
          nextTick(() => {
            tableListRef.value && (tableListRef.value.$el.children[2].scrollTop = 0)
          })
        }

        data.query.project_id = data.projectId

        const { res } = await iterationApi.getList(data.query)
        // console.log('获取迭代-列表', res.data)
        data.loading = false
        data.list = res.data
        data.total = res.total
      },
      // 每页显示条数和当前页码
      handleSizeChange (newSize: number) {
        data.query.paginate = newSize
        data.getList()
        // 滚动条回到顶部
        nextTick(() => {
          tableListRef.value.$el.children[2].scrollTop = 0
        })
      },
      handleCurrentChange (newPage: number) {
        data.query.page = newPage
        data.getList()
        // 滚动条回到顶部
        nextTick(() => {
          tableListRef.value.$el.children[2].scrollTop = 0
        })
      },

      // 新增、编辑、查看
      onAddOrEdit (type: number, id: number) {
        addOrEditDialogRef.value.showAddOrEdit(type, id)
      },

      // 删除
      async onDel (id: number) {
        const confirmResult = await ElMessageBox.confirm('是否删除此迭代？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch((err: any) => err)
        if (confirmResult !== 'confirm') return

        await iterationApi.del({ id })
        ElMessage.success('删除成功')
        data.getList()
      },

      // 开始、结束
      async editStates (id: number, status: number) {
        const confirmResult = await ElMessageBox.confirm(`是否${status === 3 ? '结束' : '开始'}此迭代？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch((err: any) => err)
        if (confirmResult !== 'confirm') return

        const form = { id, status }
        await iterationApi.changeStatus(form)
        ElMessage.success(`${status === 3 ? '结束' : '开始'}成功`)
        data.getList()
      }
    })

    // 注入
    provide('updateList', data.getList)

    return {
      ...toRefs(data),
      tableListRef,
      addOrEditDialogRef,
      queryFormRef
    }
  }
})
